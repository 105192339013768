<template>
  <b-table
    ref="exportTable"
    id="export-table"
    primary-key="id"
    :items="items"
    :fields="fields"
  >
    <template #cell(price)="data">
      {{ getPaymentUnitName(data.item) }}
      {{ data.value }}
    </template>

    <template #cell(fee)="data">
      <span class="d-inline-flex align-items-center">
        {{ data.value }}
        <b-icon
          icon="check"
          font-scale="2"
          class="common-icon-color"
          variant="success"
          v-if="data.item.feeIncluded"
        ></b-icon>
        <b-icon
          icon="x"
          v-else
          font-scale="2"
          class="common-icon-color"
        ></b-icon>
      </span>
    </template>

    <template #cell(platform)="data">
      {{ getPlatform(data.item) }}
    </template>

    <template #cell(orderTotal)="data">
      {{ getPaymentUnitName(data.item) }}
      {{ data.value }}
    </template>

    <template #cell(orderStatus)="data">
      <span class="d-inline-flex align-items-center">
        <span
          class="badge mr-1"
          :class="[getOrderBadgeAndLabel(data.item.status).itemClass]"
        >
          {{ getOrderBadgeAndLabel(data.item.status).itemLabel }}
        </span>
      </span>
    </template>

    <template #cell(paymentStateName)="data" v-if="isMerchant">
      <span class="d-inline-flex align-items-center">
        <span
          class="badge mr-1"
          :class="[paymentStateClass(data.item.paymentStateId)]"
        >
          {{
            $t(
              `PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${data.item.paymentStateId}]`
            )
          }}
        </span>
      </span>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getOrderBadgeAndLabel,
  getPaymentUnitName,
  getPlatform
} from "./../../utils/order-helpers";
import { paymentStateClass } from "@/constants";

export default {
  name: "SalesReportExport",
  props: ["items"],
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "orderNumber",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.ORDER_ID")
        },
        {
          key: "price",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PRICE"),
          class: "text-right"
        },
        {
          key: "fee",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.COMMISSION"),
          class: "text-center"
        },
        {
          key: "orderTotal",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL"),
          class: "text-right"
        },
        {
          key: "createdAt",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.DATE"),
          class: "text-center",
          formatter: value => {
            return this.$dateTimeFilter(value);
          }
        },
        {
          key: "platform",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER"),
          class: "text-center",
          sortable: true
        },
        {
          key: "orderStatus",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.STATUS"),
          class: "text-right"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isMerchant: "isMerchant"
    })
  },
  methods: {
    getOrderBadgeAndLabel: getOrderBadgeAndLabel,
    getPaymentUnitName: getPaymentUnitName,
    paymentStateClass: paymentStateClass,
    getPlatform
  },
  created() {
    if (this.isMerchant) {
      this.fields = [
        ...this.fields,
        {
          key: "paymentStateName",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATE"),
          class: "text-center"
        }
      ];
    }
  }
};
</script>
