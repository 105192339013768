<template>
  <b-form-input
    id="filter-input"
    v-model="filter"
    type="search"
    debounce="500"
    :placeholder="getSearchPlaceHolder"
    @update="$emit('filter-change', filter)"
  ></b-form-input>
</template>

<script>
export default {
  name: "TableFilter",
  props: {
    filters: Array,
    searchPlaceHolder: String
  },
  computed: {
    getSearchPlaceHolder() {
      if (this.searchPlaceHolder) return this.searchPlaceHolder;
      return this.$t("ECOMMERCE.COMMON.FILTER");
    }
  },
  data() {
    return {
      filter: null,
      selectedFilters: [],
      options: [
        {
          text: "FORM.YES",
          value: 1
        },
        {
          text: "FORM.NO",
          value: 0
        },
        {
          text: "ECOMMERCE.COMMON.ALL",
          value: undefined
        }
      ]
    };
  },
  methods: {
    getButtonText(filterIndex) {
      const currentValue = this.filters[filterIndex].currentValue;
      const optionItem = this.options.filter(
        option => option.value == currentValue
      );
      return this.$t(optionItem[0].text);
    },
    isOptionActive(filterIndex, value) {
      return this.filters[filterIndex].currentValue === value;
    }
  }
};
</script>
